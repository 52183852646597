<template>
  <OnboardPage 
    :content="content"
    :deal="deal"
  >  
    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Minimum spend description (optional)"
        v-model.trim="deal.optional_description"
      />
      <BaseSubmit
        label="Next"
        :disabled="false"
        :submitting="submitting"
      />
    </form>
    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import OnboardPage from "@/components/OnboardPage.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  components: {
    OnboardPage,
    BaseInput,
    BaseSubmit
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      deal: {
        optional_description: ''
      },
      content: {
        page: 'organization-deal-description',
        step: 3,
        title: 'Deal preview',
        subtitle: 'The text you input here will replace the minimum spend only. The reward will still appear so no need to add that here.  Also, there is no need for a period (.). We will add that.',
        nextPage: "organization-deal-disclaimers",
        showBack: true
      }
    }
  },

  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal;     
    },
    minSpend: function() {
      return Math.round(this.deal.min_spend / 100)
    },
    awardValue: function() {
      return Math.round(this.deal.award_value / 100)
    }
  },
  mounted() {
    if (this.currentBusiness && 
        this.currentBusiness.slug && 
        this.currentDeal &&
        this.currentDeal.slug
      ) {
      
      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.deal = this.currentDeal
      }  

    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.submitting = true;

      if (this.deal.optional_description != '') {
        this.deal.description = `${this.deal.optional_description}. Earn $${this.awardValue}`
      } else {
        this.deal.description = `Spend $${this.deal.min_spend}. Earn $${this.awardValue}`
      }      

      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
        slug: this.currentDeal.slug,
        deal: {
          description: this.deal.description,
          optional_description: this.deal.optional_description
        }
      }
      this.$store.dispatch("updateDeal", params)
        .then(() => {
          this.$router.push('/o/' + this.content.nextPage)
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.error(error)
            this.error = error
          }
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
